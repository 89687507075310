<template>
  <div>
    <MathInput></MathInput>
  </div>
</template>

<script>
import MathInput from "@/components/MathInput";
import {Action} from '@/store/transact/types'
import {Action as BudgetAction} from '@/store/budgetTransact/types'

export default {
  name: "Test",
  components: {
    MathInput,
  },
  mounted() {
    console.log('firing')
    this.$store.dispatch(`${[Action.TransactList]}`, {})
    console.log('1')
    this.$store.dispatch(`${[BudgetAction.BudgetTransactList]}`, {})
    console.log('2')
  }
}
</script>

<style scoped>

</style>
