<template>
  <div>
    <v-text-field
        :label="label"
        :disabled="disabled"
        v-model="internalValue"
        v-on:change="change"
    >
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: "MathInput",
  data: () => ({
    internalValue: 0,
    realValue: 0,
  }),
  methods: {
    change: function(event) {
      console.log(event)
      console.log(this.internalValue)
    }
  },
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>

</style>